import Example1 from "@/components/QualityPrediction/Example_1/Example_1.vue";
import Example2 from "@/components/QualityPrediction/Example_2/Example_2.vue";
import Example3 from "@/components/QualityPrediction/Example_3/Example_3.vue";

export default {
  name: "QualityPrediction",
  components: {
    Example1,
    Example2,
    Example3,
  },
  data() {
    return {
      qualityTabs: 0,
    };
  },
};
