<template>
  <div id="app">
    <MainPage />
    <DetailPage />
    <IntroductionPage />
    <DiagramPage />
    <StrengthPage />
    <!-- <FaqsPage /> -->
    <ContactPage />
    <AddressPage />
  </div>
</template>

<script>
import MainPage from "./views/1_MainPage/MainPage.vue";
import DetailPage from "./views/2_DetailPage/DetailPage.vue";
import IntroductionPage from "./views/3_IntroductionPage/IntroductionPage.vue";
import DiagramPage from "./views/4_DiagramPage/DiagramPage.vue";
import StrengthPage from "./views/5_StrengthPage/StrengthPage.vue";
// import FaqsPage from "./views/6_FaqsPage/FaqsPage.vue";
import ContactPage from "./views/7_ContactPage/ContactPage.vue";
import AddressPage from "./views/8_AddressPage/AddressPage.vue";
import "./fonts/NotoSansKR.css";
import "./components/common.scss";

export default {
  name: "App",
  components: {
    MainPage,
    DetailPage,
    IntroductionPage,
    DiagramPage,
    StrengthPage,
    // FaqsPage,
    ContactPage,
    AddressPage,
  },
};
</script>

<style>
body {
  width: 100%;
  overflow-x: hidden;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif, "Noto Sans KR";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
;
