import { render, staticRenderFns } from "./Example_2.vue?vue&type=template&id=b3b04a3a&"
import script from "./Example_2.js?vue&type=script&lang=js&"
export * from "./Example_2.js?vue&type=script&lang=js&"
import style0 from "./Example_2.scss?vue&type=style&index=0&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports