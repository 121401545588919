import AnomalyDetection from "@/components/AnomalyDetection/AnomalyDetection.vue";
import QualityPrediction from "@/components/QualityPrediction/QualityPrediction.vue";
import CauseAnalysis from "@/components/CauseAnalysis/CauseAnalysis.vue";
import PredictiveMaintenance from "@/components/PredictiveMaintenance/PredictiveMaintenance.vue";

export default {
  name: "DetailPage",
  components: {
    AnomalyDetection,
    QualityPrediction,
    CauseAnalysis,
    PredictiveMaintenance,
  },
  methods: {
    onSelect(e) {
      console.log(e);
    },
  },
  data() {
    return {
      tabs: 0,
    };
  },
};
