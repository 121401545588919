import Example1 from "@/components/CauseAnalysis/Example_1/Example_1.vue";
import Example2 from "@/components/CauseAnalysis/Example_2/Example_2.vue";

export default {
  name: "CauseAnalysis",
  components: {
    Example1,
    Example2,
  },
  data() {
    return {
      causeTabs: 0,
    };
  },
};
